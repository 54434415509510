import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import BackgroundImage from 'gatsby-background-image';
import { Heading, Text, Box, Flex } from 'rebass';
import { Btn } from '../components/Elements';
import Fade from 'react-reveal/Fade';
import scrollTo from 'gatsby-plugin-smoothscroll';

const BackgroundSection = ({ title, imgHero, fontColor }) => {
  return (
    <Box
      css={`
        .heroImage {
          background-position: bottom;
        }
        font-family: utopia-std, serif;
        section {
          width: 100%;
          display: flex;
          min-height: 700px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h1 {
            margin: 0 auto 0;
            font-weight: 600;
            letter-spacing: 6px;
            text-shadow: 0px 0px 20px rgba(255, 255, 255, 1);
            color: #fff;
            font-size: 52px;
            text-transform: uppercase;
            text-align: center;
            @media (max-width: 600px) {
              font-size: 32px;
            }
          }
          span {
            font-size: 18px;
            color: #fff;
          }
        }
        .mobileIntro {
          display: none;
        }
        @media (max-width: 600px) {
          section {
            min-height: 50vh;
            h1,
            span {
              display: none;
            }
          }
          .mobileIntro {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 40px;
            h1,
            span {
              color: ${fontColor};
            }
            span {
              font-family: acumin-pro;
            }
          }
        }
      `}
      display={'flex'}
      flexDirection="column"
      alignItems={'center'}
      width={'100%'}
    >
      <BackgroundImage Tag="section" fluid={imgHero.fluid}>
        <span>Sisustustyyli</span>
        <h1>{title}</h1>
      </BackgroundImage>
      <div className="mobileIntro">
        <span>Sisustustyyli</span>
        <h1>{title}</h1>
      </div>
    </Box>
  );
};

export const HeroTyylitPost = styled(BackgroundSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`;
