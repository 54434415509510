import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, LinkBtnGold } from './Elements';
import { Heading, Text, Box, Flex } from 'rebass';

const FooterPre = () => (
  <StaticQuery
    query={graphql`
      query FooterPreQuery {
        datoCmsSivusto {
          tausta3 {
            url
          }
        }
      }
    `}
    render={data => (
      <Box
        color={'beigeBg'}
        bg={'blueDark'}
        minHeight={446}
        sx={{
          display: 'none',
          backgroundImage: 'url(' + data.datoCmsSivusto.tausta3.url + ')',
          backgroundSize: 'cover',
        }}
      >
        <Container
          minHeight={446}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          color={'beigeText'}
        >
          <Heading my={2} fontSize={6} textAlign="center">
            Tilaratkaisu 48 tunnissa
          </Heading>
          <Text my={2} maxWidth={720} textAlign="center">
            Kiinnostuitko? Laske hinta-arvio ja ota yhteyttä. Saat lopullisen
            tarjouksen kahden arkipäivän sisällä.
          </Text>
          <Flex my={2} justifyContent="center">
            <LinkBtnGold to="/tilaratkaisu/#tila-ja-hinta">
              Laske hinta-arvio
            </LinkBtnGold>
          </Flex>
        </Container>
      </Box>
    )}
  />
);

export default FooterPre;
