import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import { Article, Main, Container } from '../components/Elements';
import { Box, Heading, Text, Flex } from 'rebass';
import Img from 'gatsby-image';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';
import { BackButton } from '../components/BackButton';
import _map from 'lodash/map';
import fiText from '../locales/fi.js';
import ImageTabs from '../components/ImageTabs';
import { HeroTyylitPost } from '../components/HeroTyylitPost';
import NetlifyForm3 from '../components/FormNetlify3';

const SisustustyylitNew = ({ data }) => {
  const { title, seoMetaTags, kuva, id, bgcolor } = data.project;
  const { edges } = data.thumbs;

  const { red, green, blue } = bgcolor;
  const fontColor = red + green + blue > 383 ? '#001826' : '#E0D1B6';
  const isBeige = bgcolor?.hex === '#e0d1b6' ? true : false;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Header logo="white" />
      <Main bg={bgcolor?.hex} pt={0} pb={5}>
        <HeroTyylitPost title={title} imgHero={kuva} fontColor={fontColor} />
        <section
          css={`
            display: flex;
            flex-direction: column;
            align-items: center;

            p.firstParagraphe {
              padding: 80px 15px 40px;
              text-align: center;
              font-family: utopia-std, serif;
              font-size: 28px;
              max-width: 800px;
              color: ${fontColor};
            }
            p.secondParagraphe {
              padding: 0 15px 70px;
              text-align: center;
              font-family: acumin-pro;
              font-size: 20px;
              max-width: 800px;
              color: ${fontColor};
            }
            @media (max-width: 600px) {
              p.firstParagraphe {
                padding: 40px 15px 30px;
                font-size: 22px;
              }
              p.secondParagraphe {
                font-size: 16px;
              }
            }
          `}
        >
          <p className="firstParagraphe">{data.project.firstParagraphe}</p>
          <p className="secondParagraphe">{data.project.secondParagraphe}</p>
        </section>
        <section
          css={`
            padding-left: 0;
            padding-right: 0;
            position: relative;
            .imgBackground {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 700px;
              @media (max-width: 600px) {
                height: 275px;
              }
            }
          `}
        >
          <Img
            className="imgBackground"
            fluid={data.project.secondImg?.fluid}
            alt={data.project.secondImg?.alt}
          />
        </section>
        <section
          css={`
            padding: 80px 0 80px;
          `}
        >
          <ImageTabs kuvat={data.project.catalogue} isBeige={isBeige} />
        </section>
        <section
          css={`
            margin-top: 30px;
            display: none;
            padding: 72px 0 60px;
            background: #296474;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: rgb(223, 208, 183);
            > h2 {
              font-size: 42px;
              font-family: utopia-std;
              letter-spacing: 2px;
              text-transform: uppercase;
              margin-bottom: 30px;
            }
            p {
              max-width: 600px;
              margin-bottom: 0;
            }
            form {
              max-width: 900px;
              width: 100%;
            }
          `}
        >
          <h2>{data.lataaEsite.otsikko}</h2>
          <p>{data.lataaEsite.teksti}</p>
          <a
            href={data.lataaEsite.esite.url}
            target="_blank"
            className="downloadLink"
            css={{ marginTop: 20, textDecoration: 'underline' }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                fill="#DFD0B7"
              />
            </svg>
            Lataa kohde-esite (PDF)
          </a>
          <a
            href={data.lataaEsite.esite2.url}
            target="_blank"
            className="downloadLink"
            css={{ marginTop: 10, textDecoration: 'underline' }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                fill="#DFD0B7"
              />
            </svg>
            Lataa sisustustyylikooste (PDF)
          </a>
          {/*<NetlifyForm3
            urlEsite={data.lataaEsite.esite.url}
            urlEsiteSisustus={data.lataaEsite.esite2.url}
          />*/}
        </section>
        <Container textAlign="center">
          <Text
            mx="auto"
            mt={5}
            fontSize={'38px'}
            fontFamily="utopia-std"
            textTransform="unset"
            fontWeight={400}
            color={fontColor}
          >
            Katso muut tyylit
          </Text>
        </Container>
        <Flex maxWidth={1088} mx="auto" px={[1, 1, 0]} mt={3} flexWrap="wrap">
          {_map(edges, thumb => (
            <Flex pb={3} width={[1, 1 / 3]}>
              <Link
                className="aStretch"
                to={`/tyyli-ja-sisustus/${thumb.node.slug}/`}
              >
                <Box
                  width="90%"
                  mb={2}
                  mx="auto"
                  maxWidth="500px"
                  maxHeight="277px"
                  overflow="hidden"
                >
                  <Img
                    fluid={thumb.node.kuva?.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={thumb.node.kuva?.alt}
                  />
                </Box>
                <Heading
                  as="h3"
                  mx="auto"
                  fontSize={5}
                  pb={3}
                  color={fontColor}
                  fontWeight="600"
                  lineHeight="34px"
                  width="90%"
                  maxWidth="500px"
                  textAlign="left"
                >
                  {thumb.node.title}
                </Heading>
              </Link>
            </Flex>
          ))}
        </Flex>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

export const projectQuery = graphql`
  query($slug: String!) {
    project: datoCmsSisustustyylit(slug: { eq: $slug }) {
      id
      title
      bgcolor {
        hex
        red
        green
        blue
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      kuva {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      title
      firstParagraphe
      secondParagraphe
      secondImg {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      catalogue {
        title
        titleShort
        img {
          fluid(maxWidth: 740, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    lataaEsite: datoCmsLataaEsite {
      otsikko
      teksti
      esite {
        url
      }
      esite2 {
        url
      }
    }
    thumbs: allDatoCmsSisustustyylit(
      filter: { slug: { ne: $slug } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          kuva {
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;

SisustustyylitNew.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SisustustyylitNew;
