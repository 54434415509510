import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Text } from 'rebass';
import Img from 'gatsby-image';
import TransitionsModal from './ModalFloors';

const ImageTabs = ({ kuvat, isBeige }) => {
  return (
    <div
      css={`
        .tabs {
          margin: 0 auto;
          max-width: 924px;
        }
        .tabList {
          padding-left: 0;
          display: flex;
          padding-left: 1px;
          padding-right: 1px;
          margin-bottom: 70px;
          .tab {
            margin-left: 1px;
            margin-right: 1px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 78px;
            border-radius: 0;
            color: ${isBeige ? '#fff' : 'rgb(0, 24, 38)'};
            background: ${isBeige ? '#001826' : 'rgb(223, 208, 183)'};
            font-family: utopia-std;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: bold;
            cursor: pointer;
            text-align: center;
            span.mobile {
              display: none;
            }
            @media (max-width: 600px) {
              span.default {
                display: none;
              }
              span.mobile {
                display: inline-flex;
              }
            }
          }
          .tab.react-tabs__tab--selected {
            color: ${isBeige ? '#001826' : '#fff'};
            background: none;
          }
        }
        .tabPanel {
          display: flex;
          justify-content: center;
          .imgItem {
            width: 80%;
          }
        }
        @media (max-width: 600px) {
          .tabList {
            margin-bottom: 20px;
            .tab {
              font-size: 14px;
              letter-spacing: 1px;
              padding-left: 5px;
              padding-right: 5px;
            }
          }
          .tabPanel {
            .imgItem {
              width: 100%;
            }
          }
        }
      `}
    >
      <Tabs className="tabs">
        <TabList className="tabList">
          {kuvat.map(item => {
            return (
              <Tab className="tab" key={item.title}>
                <span className="default">{item.title}</span>
                <span className="mobile">{item.titleShort}</span>
              </Tab>
            );
          })}
        </TabList>

        {kuvat.map(item => {
          return (
            <TabPanel key={item.title} className="tabPanel">
              <Img
                className="imgItem"
                fluid={item.img?.fluid}
                alt={item.img?.alt}
              />
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ImageTabs;
